import { io } from "socket.io-client";

class SocketConnection {
    constructor() {
        this.socket = io(process.env.REACT_APP_SOCKET_URL || "wss://localhost", {
            path: "/socket/",
            autoConnect: false,
            reconnection: true,
            reconnectionAttempts: Infinity,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 10000,
            randomizationFactor: 0.5,
        });
    }

    connect(jwt) {
        try {
            if (this.socket.connected) {
                return this.socket;
            }

            this.socket.auth = { jwt };
            this.socket.connect();
            return this.socket;
        } catch (error) {
            console.error("Error connecting socket:", error);
            return null;
        }
    }

    disconnect() {
        if (this.socket.connected) {
            this.socket.disconnect();
        }
    }
}

const socketConnection = new SocketConnection();
export default socketConnection;
