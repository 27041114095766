import updateCallStatus from "../redux-elements/actions/updateCallStatus";

const proDashabordSocketListeners = (socket, setApptInfo, dispatch, setUserId, setStartCall) => {
    socket.on("apptData", (apptData) => {
        setApptInfo(apptData);
    });

    socket.on("newOfferWaiting", (offerData) => {
        dispatch(updateCallStatus("offer", offerData.offer));
        dispatch(updateCallStatus("myRole", "answerer"));
        setUserId(offerData.user_id);
        localStorage.setItem("user_id", offerData.user_id);
        setStartCall(true);
    });
};

const proVideoSocketListeners = (socket, addIceCandidateToPc, refreshPage) => {
    socket.on("iceToClient", (iceC) => {
        addIceCandidateToPc(iceC);
    });

    socket.on("finalizarChamada", () => {
        refreshPage();
    });
};

export default { proDashabordSocketListeners, proVideoSocketListeners };
