// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-login {
    max-width: 400px;
    width: 100%;
    object-fit: cover;
}

.card-login {
    min-width: 500px !important;
    background-color: rgb(63, 61, 61) !important;
    color: aliceblue !important;
    font-size: 22px !important;
    font-weight: 500 !important; 
}

.btn-login {
    background-color: #3fb643 !important; 
    color: rgb(255, 255, 255) !important; 
    border: none !important;
    padding: 10px 10px !important;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); 
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    font-size: 22px !important;
    font-weight: 600 !important; 
}`, "",{"version":3,"sources":["webpack://./src/siteComponents/LoginPage.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,2BAA2B;IAC3B,4CAA4C;IAC5C,2BAA2B;IAC3B,0BAA0B;IAC1B,2BAA2B;AAC/B;;AAEA;IACI,oCAAoC;IACpC,oCAAoC;IACpC,uBAAuB;IACvB,6BAA6B;IAC7B,kBAAkB;IAClB,0CAA0C;IAC1C,4DAA4D;IAC5D,0BAA0B;IAC1B,2BAA2B;AAC/B","sourcesContent":[".logo-login {\n    max-width: 400px;\n    width: 100%;\n    object-fit: cover;\n}\n\n.card-login {\n    min-width: 500px !important;\n    background-color: rgb(63, 61, 61) !important;\n    color: aliceblue !important;\n    font-size: 22px !important;\n    font-weight: 500 !important; \n}\n\n.btn-login {\n    background-color: #3fb643 !important; \n    color: rgb(255, 255, 255) !important; \n    border: none !important;\n    padding: 10px 10px !important;\n    border-radius: 5px;\n    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); \n    transition: background-color 0.3s ease, box-shadow 0.3s ease;\n    font-size: 22px !important;\n    font-weight: 600 !important; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
