import React, { useState } from "react";
import "./Sidebar.css";
import * as AiIcons from "react-icons/ai";
import { useLocation } from "react-router-dom";

import { NavLink } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

function Sidebar() {
    const location = useLocation();

    const [isSidebarVisible, setIsSidebarVisible] = useState(true);

    const isActive = (path) => {
        return location.pathname === path;
    };

    const logout = () => {
        localStorage.clear();
        window.location.reload();
    };

    return (
        <>
            <div className="sidebar">
                <div className="logo-container-side">
                    <img src="https://strike-view-advertising.s3.amazonaws.com/63fd5010bc8c90fc606d8a53/Strike-TI-Logo.png" alt="Logo" className="logo-side" />
                </div>
                <div className="menu-container">
                    <div className="menu sidebar-header">
                        <div className={`container-menu-item ${isActive("/empresas") ? "side-active" : ""}`}>
                            <NavLink to="/empresas" className="menu-text">
                                <div className="menu-item">
                                    <li className="menu-icons">
                                        <AiIcons.AiOutlineMail />
                                    </li>
                                    Empresas
                                </div>
                            </NavLink>
                        </div>
                        <div className={`container-menu-item ${isActive("/grupos") ? "side-active" : ""}`}>
                            <NavLink to="/grupos" className="menu-text">
                                <div className="menu-item">
                                    <li className="menu-icons">
                                        <AiIcons.AiOutlineUsergroupAdd />
                                    </li>
                                    Grupos
                                </div>
                            </NavLink>
                        </div>

                        <div className={`container-menu-item ${isActive("/usuarios") ? "side-active" : ""}`}>
                            <NavLink to="/usuarios" className="menu-text">
                                <div className="menu-item">
                                    <li className="menu-icons">
                                        <AiIcons.AiOutlineUserAdd />
                                    </li>
                                    Usuários
                                </div>
                            </NavLink>
                        </div>

                        <div className={`container-menu-item ${isActive("/usuarios-online") ? "side-active" : ""}`}>
                            <NavLink to="/usuarios-online" className="menu-text">
                                <div className="menu-item">
                                    <li className="menu-icons">
                                        <AiIcons.AiOutlineUserAdd />
                                    </li>
                                    Online
                                </div>
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className={`container-menu-item-logout`}>
                    <NavLink onClick={logout} className="menu-text">
                        <div className="menu-item-logout">
                            <li className="menu-icons">
                                <AiIcons.AiOutlineLogout />
                            </li>
                            Logout
                        </div>
                    </NavLink>
                </div>
            </div>
            <div className="sidebarSmall">
                <div className={`container-menu-item ${isActive("/empresas") ? "side-active" : ""}`}>
                    <NavLink to="/empresas" className="menu-text">
                        <div className="menu-item">
                            <li className="menu-icons">
                                <AiIcons.AiOutlineMail />
                            </li>
                            Empresas
                        </div>
                    </NavLink>
                </div>

                <div className={`container-menu-item ${isActive("/grupos") ? "side-active" : ""}`}>
                    <NavLink to="/grupos" className="menu-text">
                        <div className="menu-item">
                            <li className="menu-icons">
                                <AiIcons.AiOutlineUsergroupAdd />
                            </li>
                            Grupos
                        </div>
                    </NavLink>
                </div>

                <div className={`container-menu-item ${isActive("/usuarios") ? "side-active" : ""}`}>
                    <NavLink to="/usuarios" className="menu-text">
                        <div className="menu-item">
                            <li className="menu-icons">
                                <AiIcons.AiOutlineUserAdd />
                            </li>
                            Usuários
                        </div>
                    </NavLink>
                </div>

                <div className={`container-menu-item ${isActive("/usuarios-online") ? "side-active" : ""}`}>
                    <NavLink to="/usuarios-online" className="menu-text">
                        <div className="menu-item">
                            <li className="menu-icons">
                                <AiIcons.AiOutlineUserAdd />
                            </li>
                            Online
                        </div>
                    </NavLink>
                </div>
                <div className={`container-menu-item-logout`}>
                    <NavLink onClick={logout} className="menu-text">
                        <div className="menu-item-logout">
                            <li className="menu-icons">
                                <AiIcons.AiOutlineLogout />
                            </li>
                            <button className="icon-button" onClick={logout}>
                                <FontAwesomeIcon icon={faSignOutAlt} />
                            </button>
                            Logout
                        </div>
                    </NavLink>
                </div>
            </div>
        </>
    );
}

export default Sidebar;
