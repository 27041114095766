import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Container, Card, Alert } from "react-bootstrap";
import axios from "axios";
import "./LoginPage.css";

function LoginPage() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErroMessage] = useState("");
    const [apptInfo, setApptInfo] = useState({});
    const [token, setToken] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErroMessage("");
        try {
            const response = await fetch(`api/auth`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ username, password }),
            });
            const data = await response.json();
            if (response.ok) {
                localStorage.setItem("token", data.token);
                setToken(data.token);
            } else {
                setErroMessage(data.message);
            }
        } catch (error) {
            console.error("Erro:", error);
        }
    };

    useEffect(() => {
        setToken(localStorage.getItem("token"));
    });

    useEffect(() => {
        const fetchDecodedToken = async () => {
            const resp = await axios.get(`api/auth/user-info`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setApptInfo(resp.data);
        };
        if (token) {
            fetchDecodedToken();
        }
    }, [token]);

    useEffect(() => {
        if (apptInfo && apptInfo.user_type) {
            if (apptInfo.user_type === "condominio") {
                navigate("/condominio");
            } else if (apptInfo.user_type === "operador") {
                navigate("/operador");
            } else if (apptInfo.user_type === "admin") {
                navigate("/empresas");
            } else {
                localStorage.clear();
                navigate("/login");
            }
        }
    }, [apptInfo]);

    return (
        <div className="login-bg">
            <Container className="d-flex justify-content-center align-items-center vh-100 width-300 login-bg">
                <Card className="p-4 shadow-lg card-login">
                    <div className="text-center mb-4 logo-login">
                        <img
                            src="https://strike-view-advertising.s3.amazonaws.com/63fd5010bc8c90fc606d8a53/Strike-TI-Logo.png"
                            alt="Logo"
                            className="img-fluid"
                            style={{ maxHeight: "100px" }}
                        />
                    </div>

                    {/* 
                <h2 className="text-center mb-4">Login</h2> 
                */}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="username">
                            <Form.Label>Usuário:</Form.Label>
                            <Form.Control type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="password">
                            <Form.Label>Senha:</Form.Label>
                            <Form.Control type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                        </Form.Group>
                        {errorMessage && (
                            <Alert variant="danger" className="text-center">
                                {errorMessage}
                            </Alert>
                        )}
                        <Button type="submit" className="w-100 btn-login">
                            Entrar
                        </Button>
                    </Form>
                </Card>
            </Container>
        </div>
    );
}

export default LoginPage;
