import { useEffect, useState, useRef } from "react";
import axios from "axios";
import "./VideoComponents.css";
import { useDispatch, useSelector } from "react-redux";

import addStream from "../redux-elements/actions/addStream";
import updateCallStatus from "../redux-elements/actions/updateCallStatus";

import createPeerConnection from "../webRTCutilities/createPeerConnection";
import socketConnection from "../webRTCutilities/socketConnection";
import clientSocketListeners from "../webRTCutilities/clientSocketListeners";

import startLocalVideoStream from "../videoComponents/VideoButton/startLocalVideoStream";

import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faExpand } from "@fortawesome/free-solid-svg-icons";
// import videoBackground from "./VideoBackground";

const baseUrl = process.env.REACT_APP_BASE_URL;

const MainVideoPage = () => {
    // const canvasRef = useRef();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const callStatus = useSelector((state) => state.callStatus);
    const streams = useSelector((state) => state.streams);
    const [userInfo, setUserInfo] = useState({});
    const smallFeedEl = useRef(null);
    const largeFeedEl = useRef(null);
    const uuidRef = useRef(null);
    const streamsRef = useRef(null);
    const [isStreaming, setIsStreaming] = useState(false);
    const [createOffer, setCreateOffer] = useState(false);
    const [toRamal, setToRamal] = useState("");

    const refreshPage = () => {
        window.location.reload();
    };

    useEffect(() => {
        const videoElement = largeFeedEl.current;

        if (videoElement) {
            const handlePlay = () => {
                setIsStreaming(true);

                // videoBackground.remove(canvasRef.current, largeFeedEl.current);
            };

            const handlePause = () => {
                setIsStreaming(false);
            };

            const handleEnded = () => {
                setIsStreaming(false);
            };

            videoElement.addEventListener("playing", handlePlay);
            videoElement.addEventListener("pause", handlePause);
            videoElement.addEventListener("ended", handleEnded);

            return () => {
                videoElement.removeEventListener("playing", handlePlay);
                videoElement.removeEventListener("pause", handlePause);
                videoElement.removeEventListener("ended", handleEnded);
            };
        }
    }, []);

    useEffect(() => {
        const fetchMedia = async () => {
            const constraints = {
                video: true,
                audio: false,
            };
            try {
                const stream = await navigator.mediaDevices.getUserMedia(constraints);
                dispatch(addStream("localStream", stream));

                const { peerConnection, remoteStream } = await createPeerConnection(addIce);
                dispatch(addStream("remote1", remoteStream, peerConnection));
                dispatch(updateCallStatus("haveMedia", true));

                largeFeedEl.current.srcObject = remoteStream;
            } catch (err) {
                console.error(err);
            }
        };
        fetchMedia();
    }, []);

    useEffect(() => {
        if (streams.remote1) {
            streamsRef.current = streams;
        }
    }, [streams]);

    useEffect(() => {
        if (callStatus.haveMedia) {
            smallFeedEl.current.srcObject = streams.localStream.stream;
            startLocalVideoStream(streams, dispatch);
        }
    }, [callStatus.haveMedia]);

    useEffect(() => {
        const createOfferAsync = async () => {
            for (const s in streams) {
                if (s !== "localStream") {
                    try {
                        const pc = streams[s].peerConnection;
                        const offer = await pc.createOffer();
                        pc.setLocalDescription(offer);
                        const token = localStorage.getItem("token");
                        const socket = socketConnection.connect(token);
                        socket.emit("newOffer", { offer, userInfo, toRamal });
                    } catch (err) {
                        console.error(err);
                    }
                }
            }
            dispatch(updateCallStatus("haveCreatedOffer", true));
        };
        if (createOffer && !callStatus.haveCreatedOffer && callStatus.haveMedia) {
            createOfferAsync();
        }
    }, [createOffer, callStatus.haveCreatedOffer, callStatus.haveMedia]);

    useEffect(() => {
        const asyncAddAnswer = async () => {
            for (const s in streams) {
                if (s !== "localStream") {
                    const pc = streams[s].peerConnection;
                    await pc.setRemoteDescription(callStatus.answer);
                }
            }
        };

        if (callStatus.answer) {
            asyncAddAnswer();
        }
    }, [callStatus.answer]);

    useEffect(() => {
        const token = localStorage.getItem("token");
        const fetchDecodedToken = async () => {
            const resp = await axios.get(`https://${baseUrl}/api/auth/user-info`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setUserInfo(resp.data);
            uuidRef.current = resp.data.user_id;
        };
        fetchDecodedToken();
    }, []);

    useEffect(() => {
        if (userInfo && userInfo.user_type) {
            if (userInfo.user_type !== "condominio") {
                localStorage.clear();
                navigate("/");
            }
        }
    }, [userInfo]);

    useEffect(() => {
        const token = localStorage.getItem("token");
        const socket = socketConnection.connect(token);
        clientSocketListeners(socket, dispatch, addIceCandidateToPc, setCreateOffer, setToRamal, refreshPage);
    }, []);

    const addIceCandidateToPc = (iceC) => {
        for (const s in streamsRef.current) {
            if (s !== "localStream") {
                const pc = streamsRef.current[s].peerConnection;
                pc.addIceCandidate(iceC);
            }
        }
    };

    const addIce = (iceC) => {
        const socket = socketConnection.connect(localStorage.getItem("token"));
        socket.emit("iceToServer", {
            iceC,
            who: "client",
            user_id: uuidRef.current,
        });
    };

    const logout = () => {
        socketConnection.disconnect();
        localStorage.clear();
        refreshPage();
    };

    const toggleFullScreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
        } else if (document.exitFullscreen) {
            document.exitFullscreen();
        }
        
    };

    return (
        <div id="main-video-page">
            <div className="info-header">
                <div className="info-header-content"></div>
                <div className="button-group">
                    <button className="icon-button" onClick={toggleFullScreen}>
                        <FontAwesomeIcon icon={faExpand} />
                    </button>
                    <button className="icon-button" onClick={logout}>
                        <FontAwesomeIcon icon={faSignOutAlt} />
                    </button>
                </div>
            </div>
            <video id="large-feed" ref={largeFeedEl} autoPlay playsInline hidden={!isStreaming}></video>
            {/* <img id="large-back" hidden={!isStreaming} src="https://atechdev.s3.us-east-1.amazonaws.com/AtendeBG.png" /> */}
            {/* <canvas id="large-feed" ref={canvasRef} autoPlay playsInline hidden={!isStreaming} /> */}
            <div className="logo-container" hidden={isStreaming}>
                <img className="logo" src="https://strike-view-advertising.s3.amazonaws.com/63fd5010bc8c90fc606d8a53/Strike-TI-Logo.png" alt="Logo" />
            </div>

            <video id="own-feed" ref={smallFeedEl} autoPlay playsInline hidden={!isStreaming}></video>
            {/* <ActionButtons smallFeedEl={smallFeedEl} largeFeedEl={largeFeedEl} /> */}
        </div>
    );
};

export default MainVideoPage;
