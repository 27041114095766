import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import "./App.css";
import MainVideoPage from "./videoComponents/MainVideoPage";
import ProMainVideoPage from "./videoComponents/ProMainVideoPage";
import LoginPage from "./siteComponents/LoginPage";
import CompaniesPage from "./siteComponents/management/CompaniesPage";
import GroupsPage from "./siteComponents/management/GroupsPage";
import UsersPage from "./siteComponents/management/UsersPage";
import OnlineUsersPage from "./siteComponents/management/OnlineUsersPage";
// import AdminPage from "./siteComponents/management/AdminPage";

// Função para obter informações do usuário a partir do endpoint /user-info
const getUser = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
        return { isAuthenticated: false, user_type: null };
    }

    try {
        const response = await axios.get("api/auth/user-info", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const { user_type } = response.data;
        return { isAuthenticated: true, user_type };
    } catch (error) {
        console.error("Erro ao obter informações do usuário:", error);
        return { isAuthenticated: false, user_type: null };
    }
};

const PrivateRoute = ({ element, allowedUserType }) => {
    const [user, setUser] = useState({ isAuthenticated: false, user_type: null, loading: true });

    useEffect(() => {
        const fetchUser = async () => {
            const userData = await getUser();
            setUser({ ...userData, loading: false });
        };
        fetchUser();
    }, []);

    if (user.loading) {
        return <div>Carregando...</div>;
    }

    if (!user.isAuthenticated) {
        localStorage.clear();
        return <Navigate to="/login" />;
    }

    if (user.user_type !== allowedUserType) {
        localStorage.clear();
        return <Navigate to="/login" />;
    }

    return element;
};

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/" element={<LoginPage />} />
                <Route path="/condominio" element={<PrivateRoute element={<MainVideoPage />} allowedUserType="condominio" />} />
                <Route path="/operador" element={<PrivateRoute element={<ProMainVideoPage />} allowedUserType="operador" />} />
                <Route path="/empresas" element={<PrivateRoute element={<CompaniesPage />} allowedUserType="admin" />} />
                <Route path="/grupos" element={<PrivateRoute element={<GroupsPage />} allowedUserType="admin" />} />
                <Route path="/usuarios" element={<PrivateRoute element={<UsersPage />} allowedUserType="admin" />} />
                <Route path="/usuarios-online" element={<PrivateRoute element={<OnlineUsersPage />} allowedUserType="admin" />} />
                {/* <Route path="/admin" element={<PrivateRoute element={<AdminPage />} allowedUserType="admin" />} /> */}
            </Routes>
        </BrowserRouter>
    );
}

export default App;
