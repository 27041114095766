import peerConfiguration from "./stunServers";

const createPeerConnection = (addIce) => {
    // console.log("createPeerConnection");
    return new Promise(async (resolve, reject) => {
        const peerConnection = new RTCPeerConnection(peerConfiguration);

        const remoteStream = new MediaStream();
        peerConnection.addEventListener("signalingstatechange", (e) => {
            // console.log("Signaling State Change");
            // console.log(e);
        });

        peerConnection.addEventListener("icecandidate", (e) => {
            if (e.candidate) {
                addIce(e.candidate);
            }
        });

        peerConnection.addEventListener("track", (e) => {
            e.streams[0].getTracks().forEach((track) => {
                const existingTracks = remoteStream.getTracks();
                const trackAlreadyAdded = existingTracks.some((t) => t.id === track.id);

                if (!trackAlreadyAdded) {
                    remoteStream.addTrack(track);
                }
            });
        });

        resolve({
            peerConnection,
            remoteStream,
        });
    });
};

export default createPeerConnection;
