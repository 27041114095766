import updateCallStatus from "../../redux-elements/actions/updateCallStatus";

const startLocalVideoStream = (streams, dispatch) => {
    const localStream = streams.localStream;
    for (const s in streams) {
        if (s !== "localStream") {
            const curStream = streams[s];
            localStream.stream.getVideoTracks().forEach((t) => {
                const senders = curStream.peerConnection.getSenders();
                const trackAlreadyAdded = senders.some((sender) => sender.track === t);

                if (!trackAlreadyAdded) {
                    curStream.peerConnection.addTrack(t, streams.localStream.stream);
                }
            });
            dispatch(updateCallStatus("video", "enabled"));
        }
    }
};

export default startLocalVideoStream;
