let peerConfiguration = {
    iceServers: [
        {
          urls: "stun:stun.relay.metered.ca:80",
        },
        {
          urls: "turn:standard.relay.metered.ca:80",
          username: "8c8cfd083b594bef0b8a56f0",
          credential: "NRxVFAO2yBYOffMi",
        },
        {
          urls: "turn:standard.relay.metered.ca:80?transport=tcp",
          username: "8c8cfd083b594bef0b8a56f0",
          credential: "NRxVFAO2yBYOffMi",
        },
        {
          urls: "turn:standard.relay.metered.ca:443",
          username: "8c8cfd083b594bef0b8a56f0",
          credential: "NRxVFAO2yBYOffMi",
        },
        {
          urls: "turns:standard.relay.metered.ca:443?transport=tcp",
          username: "8c8cfd083b594bef0b8a56f0",
          credential: "NRxVFAO2yBYOffMi",
        },
    ],
};

export default peerConfiguration;
