export const mySendRequest = async (method, url, token, data) => {
    try {
        const res = await fetch(url, {
            method: method,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });

        // const json = await res.json();
        return res;
    } catch (error) {
        return { error: "Desconectado do servidor" };
    }
};
