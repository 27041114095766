import React from "react";
import { useState, useEffect } from "react";

import { useMemo } from "react";
import { MaterialReactTable } from "material-react-table";

import { mySendRequest } from "../../hooks/useMySendRequest";
import Sidebar from "../Sidebar";

function OnlineUsersPage() {
    const token = localStorage.getItem("token");

    const [userList, setUserList] = useState([]);

    const columns = useMemo(
        () => [
            {
                accessorKey: "username",
                header: "Usuário",
                size: 100,
            },
            {
                accessorKey: "ramal",
                header: "Ramal",
                size: 100,
            },
            {
                accessorKey: "user_type",
                header: "Tipo",
                size: 100,
            },
            {
                accessorKey: "loginTimeStamp",
                header: "Ultimo login",
                size: 100,
                Cell: ({ cell }) => {
                    const timestamp = cell.getValue();
                    if (timestamp && timestamp > 0) {
                        const date = new Date(timestamp);
                        const formattedDate = `${String(date.getDate()).padStart(2, "0")}/${String(date.getMonth() + 1).padStart(
                            2,
                            "0"
                        )}/${date.getFullYear()} ${String(date.getHours()).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}`;
                        return <span>{formattedDate}</span>;
                    }
                },
            },
            {
                accessorKey: "online",
                header: "Online",
                size: 100,
                Cell: ({ cell }) => (
                    <span
                        style={{
                            display: "inline-block",
                            width: "16px",
                            height: "16px",
                            borderRadius: "50%",
                            marginLeft:"20px",
                            backgroundColor: cell.getValue() ? "green" : "red",
                        }}
                    />
                ),
            },
            {
                accessorKey: "active", // Acessa o campo 'ativo' do objeto
                header: "Ativo",
                size: 100,
                Cell: ({ cell }) => (
                    <span
                        style={{
                            color: cell.getValue() ? "green" : "red",
                            fontSize: "14px",
                            marginLeft:"10px",
                        }}
                    >
                        {cell.getValue() ? "✔️" : "❌"}
                    </span>
                ),
            },
        ],
        []
    );

    useEffect(() => {
        async function fetchData() {
            const resp = await mySendRequest("GET", `signaling/online-users`, token);
            const json = await resp.json();
            setUserList(json);
        }
        fetchData();
    }, []);

    return (
        <div className="principal">
            <Sidebar />
            <div className="management-form-page">
                <div className="management-form-container " style={{ minHeight: "240px" }}>
                    <div className="header-table">
                        <h1 className="h1-table">Usuários Online</h1>
                    </div>

                    <div className="table-container">
                        {userList && (
                            <MaterialReactTable
                                columns={columns}
                                data={userList}
                                enableColumnActions={false}
                                initialState={{ density: "compact", pagination: { pageSize: 20 } }}
                                enableDensityToggle={false}
                                enableFullScreenToggle={false}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OnlineUsersPage;
