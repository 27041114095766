import updateCallStatus from "../redux-elements/actions/updateCallStatus";

const clientSocketListeners = (socket, dispatch, addIceCandidateToPc, setCreateOffer, setToRamal, refreshPage) => {
    socket.on("answerToClient", (answer) => {
        dispatch(updateCallStatus("answer", answer));
        dispatch(updateCallStatus("myRole", "offerer"));
    });

    socket.on("iceToClient", (iceC) => {
        addIceCandidateToPc(iceC);
    });

    socket.on("iniciarChamada", (callInfo) => {
        setToRamal(callInfo.toRamal);
        setCreateOffer(true);
    });

    socket.on("finalizarChamada", () => {
        refreshPage();
    });
};

export default clientSocketListeners;
